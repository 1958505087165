import { Canvas } from '@react-three/fiber';
import React from "react";
import { OrbitControls, Stage, PerspectiveCamera, Loader} from "@react-three/drei";
import { Suspense } from "react";
import Exhaust from "./models/Exhaust";
import './App.css';

function App() {
    const ref = React.useRef()
    return (
      <div className="App">
          <Canvas shadows dpr={[1, 2]} camera={{ fov: 50 }} style={{ background: "rgb(148 77 255)" }}>
              <Suspense fallback={null}>
                  <Stage controls={ref} preset="portrait" intensity={2}  shadows={false} environment="apartment">
                      false
                      <Exhaust />
                      false
                  </Stage>
              </Suspense>
              <OrbitControls ref={ref} autoRotate />
          </Canvas>
          <Loader />
      </div>
  );
}

export default App;
