/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

const audio = new Audio("/exhaust.mp3");

const useAudio = url => {
    const [audio] = React.useState(new Audio(url));
    const [playing, setPlaying] = React.useState(false);

    const toggle = () => setPlaying(!playing);

    React.useEffect(() => {
            playing ? audio.play() : audio.pause();
            audio.currentTime = 0;
        },
        [playing]
    );

    React.useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    return [playing, toggle];
};

export default function Exhaust(props) {
    const group = useRef();
    const { nodes, materials, animations } = useGLTF(
        "/Elisa_tex.gltf"
    );

    const [playing, toggle] = useAudio('/exhaust.mp3');


    return (
        <group {...props} dispose={null} onClick={()=>{audio.play()}}>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Cube008.geometry}
                material={materials.Akrapovic}
                position={[2.738, 2.367, 0.06]}
                scale={[1.656, 1, 1]}
            />
        </group>
    );
}

useGLTF.preload("/Elisa_tex.gltf");